<script lang="ts" setup>
import type { HomeJackpot, HomeGamesList } from "@/types";

defineProps<{
	jackpot: HomeJackpot;
	jackpotGames: HomeGamesList[];
}>();

const { isSweepStakes } = useSwitchMode();
const { t } = useT();
const isGuest = useIsGuest();
const { isDesktop } = useDevice();

const isEntriesMode = computed(() => isSweepStakes.value && !isGuest.value);
</script>
<template>
	<div v-if="jackpotGames?.length">
		<div :class="['jackpot-slots', { 'mode-entries': isEntriesMode, 'mobile-slots': !isDesktop }]">
			<div class="info">
				<AText v-if="isDesktop" variant="taipei" :modifiers="['bold', 'center']" class="text-cannes title" as="div">
					<i18n-t keypath="Get extra Gold Coins with {key}">
						<template #key>
							<span>{{ t("Progressive Jackpot") }}</span>
						</template>
					</i18n-t>
				</AText>
				<AText v-else variant="tanta" :modifiers="['bold', 'center', 'uppercase']" class="text-cannes title" as="div">
					{{ t("Total jackpot amount") }}
				</AText>

				<div class="jackpot-slots-counter">
					<OHomeJackpotCounter :jackpots="jackpot" :isSweepStakes="isEntriesMode" />
				</div>
			</div>

			<div class="jackpot-slots-content">
				<OGameSlider
					:games="jackpotGames"
					title="Jackpot games"
					show-all
					@view-all-action="navigateTo('/issues/jackpot/')"
				/>

				<NuxtLink v-if="!isDesktop" to="/issues/jackpot/">
					<AButton variant="outline" size="md" class="btn">
						<AText :modifiers="['uppercase']">{{ t("View all") }}</AText>
					</AButton>
				</NuxtLink>
			</div>

			<NuxtImg
				:src="`/nuxt-img/jackpot-slots/jacpot-bg${!isDesktop ? '-mob' : ''}.png`"
				:width="isDesktop ? 760 : 328"
				:height="isDesktop ? 388 : 94"
				format="avif"
				loading="lazy"
				class="bg"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
:deep(.box-slider) {
	.header-slider {
		@include media-breakpoint-up(lg) {
			padding: 0 8px;
		}
		@include media-breakpoint-down(xl) {
			padding: 0 4px;
		}
	}

	.cards-feed__container {
		@include media-breakpoint-down(lg) {
			padding-top: 52px;
		}

		.wrapper {
			width: 136px;

			@include media-breakpoint-down(xl) {
				width: 101px;
				padding: 0 4px;
				gap: 8px;
			}
		}
	}

	.content-right {
		flex-direction: row-reverse;
	}
}

.jackpot-slots {
	width: 100%;
	min-height: 388px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 12px;
	background: var(--galway);
	padding: 24px 24px 24px 32px;
	gap: 32px;
	overflow: hidden;
	position: relative;

	@include media-breakpoint-down(xl) {
		min-height: 320px;
	}

	@include media-breakpoint-down(lg) {
		gap: 32px;
		flex-direction: column;
	}

	&.mode-entries {
		background: var(--geneva);
	}

	.jackpot-slots-counter {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		margin-top: 12px;

		&:deep(.jp-counter) {
			display: block;
			margin: 0;
		}
	}

	.jackpot-slots-content {
		width: 544px;
		position: relative;
		z-index: 1;

		@include media-breakpoint-down(xl) {
			width: 404px;
		}
	}
}

.info {
	position: relative;
	z-index: 1;
}

.bg {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	object-fit: contain;

	@include media-breakpoint-down(lg) {
		height: auto;
		width: 100%;
	}
}

.btn {
	width: 100%;
	margin-top: 16px;
}

.jackpot-slots.mobile-slots {
	gap: 8px;
	padding: 32px 16px;

	.jackpot-slots-content {
		width: 304px;
	}

	.jackpot-slots-counter {
		margin-top: 4px;
	}

	&:deep(.box-slider) {
		.header-slider {
			justify-content: center;
		}

		.content-right {
			display: none;
		}

		.cards-feed__container {
			padding-top: 44px;
		}
	}
}
</style>
